<template>
  <v-container fluid>
    <v-row>
      <DonutChart :data="items"></DonutChart>
      <DonutChart :data="items"></DonutChart>
      <DonutChart :data="items"></DonutChart>
    </v-row>
    <ReservationsTable title="Items Table" :headers="headers" :items="items"></ReservationsTable>
  </v-container>
</template>

<script>
import ReservationsTable from './ReservationsTable.vue'
import DonutChart from './DonutChartCard.vue'
export default {
  components: {
    ReservationsTable,
    DonutChart,
  },
  data: () => ({
    headers: [
      { sortable: true, text: 'Name', value: 'nombre' },
      { sortable: true, text: 'Apellido', value: 'apellido' },
      { sortable: true, text: 'Email', value: 'correo' },
      { sortable: true, text: 'Status', value: 'estatus' },
    ],

    items: [
      {
        nombre: 'Juan',
        apellido: 'Perez',
        correo: 'juanperez@gmail.com',
        estatus: 'Pendiente',
        origen: 'Aeropuerto',
        destino: 'Alborada',
        fecha:
          new Date().getDay() +
          1 +
          '/' +
          new Date().getMonth() +
          1 +
          '/' +
          new Date().getFullYear(),
        hora: new Date().getHours() + ':' + new Date().getMinutes(),
      },
      {
        nombre: 'name1',
        apellido: 'lastname1',
        correo: 'test1@gmail.com',
        estatus: 'Pendiente',
        origen: 'Aeropuerto',
        destino: 'Alborada',
        fecha:
          new Date().getDay() +
          1 +
          '/' +
          new Date().getMonth() +
          1 +
          '/' +
          new Date().getFullYear(),
        hora: new Date().getHours() + 2 + ':' + new Date().getMinutes(),
      },
      {
        nombre: 'name2',
        apellido: 'lastname2',
        correo: 'test2@gmail.com',
        estatus: 'Pagado',
        origen: 'Altabrisa',
        destino: 'Aeropuerto',
        fecha:
          new Date().getDay() +
          1 +
          '/' +
          new Date().getMonth() +
          1 +
          '/' +
          new Date().getFullYear(),
        hora: new Date().getHours() + ':' + new Date().getMinutes(),
      },
      {
        nombre: 'name3',
        apellido: 'lastname3',
        correo: 'test3@gmail.com',
        estatus: 'Finalizado',
        origen: 'Altabrisa',
        destino: 'Aeropuerto',
        fecha:
          new Date().getDay() +
          1 +
          '/' +
          new Date().getMonth() +
          1 +
          '/' +
          new Date().getFullYear(),
        hora: new Date().getHours() + ':' + new Date().getMinutes(),
      },
      {
        nombre: 'name4',
        apellido: 'lastname4',
        correo: 'test4@gmail.com',
        estatus: 'En curso',
        origen: 'Altabrisa',
        destino: 'Aeropuerto',
        fecha:
          new Date().getDay() +
          1 +
          '/' +
          new Date().getMonth() +
          1 +
          '/' +
          new Date().getFullYear(),
        hora: new Date().getHours() + ':' + new Date().getMinutes(),
      },
    ],
  }),

  methods: {},
}
</script>

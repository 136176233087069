<template>
  <v-col cols="12" sm="6" lg="4">
    <base-material-card color="primary" id="pie" :icon="icon" :title="title">
      <chartist type="Pie" style="max-height: 160px" :data="dataset" :options="options" />
      <v-divider class="ma-3" />
      <div class="px-3">
        <div class="body-2 text-uppercase grey--text font-weight-bold mb-3">Legend</div>
        <v-row align="center" class="ma-0">
          <v-col v-for="(legend, index) in chartLegends" :key="index">
            <v-avatar class="mr-1" :color="colors[index]" size="10" />
            <span class="mr-3 font-weight-light">{{ legend }}</span>
          </v-col>
        </v-row>
      </div>
    </base-material-card>
  </v-col>
</template>

<script>
export default {
  props: {
    data: Array,
    options: {
      type: Object,
      default: function () {
        return {
          donut: true,
          donutWidth: 30,
          showLabel: true,
        }
      },
    },
    title: {
      type: String,
      default: 'Donut Chart',
    },

    icon: {
      type: String,
      default: 'mdi-chart-pie',
    },
  },

  data: () => ({
    dataset: {
      series: [],
    },

    chartLegends: [],

    colors: [
      '#ffc107',
      '#2ecc71',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#16a085',
      '#27ae60',
      '#2980b9',
      '#8e44ad',
      '#2c3e50',
      '#f1c40f',
      '#e67e22',
      '#e74c3c',
      '#95a5a6',
      '#f39c12',
      '#d35400',
      '#c0392b',
      '#bdc3c7',
      '#7f8c8d',
    ],
  }),

  created() {
    this.makeNamesSetForDonutChart()
    this.setDonutData()
  },

  methods: {
    makeNamesSetForDonutChart() {
      let set = new Set(this.getAllStatus())
      this.chartLegends = [...set]
    },

    setDonutData() {
      this.dataset.series = [
        this.getNumberOfPendingStatusReservations(),
        this.getNumberOfPaidStatusReservations(),
        this.getNumberOfFinalizedStatusReservations(),
        this.getNumberOfOnCourseStatusReservations(),
      ]
    },

    getNumberOfPendingStatusReservations() {
      return this.getAllStatus().filter((element) => element === 'Pendiente').length
    },

    getNumberOfPaidStatusReservations() {
      return this.getAllStatus().filter((element) => element === 'Pagado').length
    },

    getNumberOfFinalizedStatusReservations() {
      return this.getAllStatus().filter((element) => element === 'Finalizado').length
    },

    getNumberOfOnCourseStatusReservations() {
      return this.getAllStatus().filter((element) => element === 'En curso').length
    },

    getAllStatus() {
      return this.data.map((client) => client.estatus)
    },
  },
}
</script>

<style lang="sass">
#pie
  .ct-series-a .ct-slice-donut
    stroke: #ffc107
  .ct-series-b .ct-slice-donut
    stroke: #2ecc71
  .ct-series-c .ct-slice-donut
    stroke: #3498db
  .ct-series-d .ct-slice-donut
    stroke: #9b59b6
  .ct-series-e .ct-slice-donut
    stroke: #34495e
  .ct-series-f .ct-slice-donut
    stroke: #16a085
  .ct-series-g .ct-slice-donut
    stroke: #27ae60
  .ct-series-h .ct-slice-donut
    stroke: #2980b9
  .ct-series-i .ct-slice-donut
    stroke: #8e44ad
  .ct-series-j .ct-slice-donut
    stroke: #2c3e50
  .ct-series-k .ct-slice-donut
    stroke: #f1c40f
  .ct-series-l .ct-slice-donut
    stroke: #e67e22
  .ct-series-m .ct-slice-donut
    stroke: #e74c3c
  .ct-series-n .ct-slice-donut
    stroke: #95a5a6
  .ct-series-o .ct-slice-donut
    stroke: #f39c12
  .ct-series-p .ct-slice-donut
    stroke: #d35400
  .ct-series-q .ct-slice-donut
    stroke: #c0392b
  .ct-series-r .ct-slice-donut
    stroke: #bdc3c7
  .ct-series-s .ct-slice-donut
    stroke: #7f8c8d
</style>

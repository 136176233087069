<template>
  <v-col cols="12" md="12">
    <base-material-card class="px-5 py-3" color="primary">
      <template v-slot:heading>
        <v-tabs v-model="tabs" background-color="transparent" slider-color="white">
          <v-tab class="mr-3">
            <v-icon class="mr-2"> mdi-sort-alphabetical-variant </v-icon>
            Recent
          </v-tab>
          <v-tab class="mr-3">
            <v-icon class="mr-2"> mdi-airplane-takeoff </v-icon>
            Departing Today
          </v-tab>
          <v-tab>
            <v-icon class="mr-2"> mdi-airplane-landing </v-icon>
            Arriving Today
          </v-tab>
          <v-text-field
            append-icon="mdi-magnify"
            class="ml-auto"
            hide-details
            label="Búsqueda"
            single-line
            solo-inverted
            style="max-width: 250px"
            v-model="search"
          />
        </v-tabs>
      </template>

      <v-tabs-items v-model="tabs" class="transparent">
        <v-tab-item>
          <v-card-text>
            <v-data-table :headers="tabsHeaders.recent" :items="travels.recent">
              <template v-slot:[`item.estatus`]="{ item }">
                <v-chip-group column active-class="primary--text">
                  <v-chip
                    class="ma-1"
                    label
                    x-small
                    :color="colors[item.estatus]"
                    text-color="white"
                  >
                    {{ item.estatus }}
                  </v-chip>
                </v-chip-group>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon @click="printItem(item)">mdi-printer</v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        </v-tab-item>
        <v-tab-item>
          <v-card-text>
            <v-data-table :headers="tabsHeaders.departing" :items="travels.departing">
              <template v-slot:[`item.estatus`]="{ item }">
                <v-chip-group column active-class="primary--text">
                  <v-chip
                    class="ma-1"
                    label
                    x-small
                    :color="colors[item.estatus]"
                    text-color="white"
                  >
                    {{ item.estatus }}
                  </v-chip>
                </v-chip-group>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon @click="printItem(item)">mdi-printer</v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        </v-tab-item>
        <v-tab-item>
          <v-card-text>
            <v-data-table :headers="tabsHeaders.arriving" :items="travels.arriving">
              <template v-slot:[`item.estatus`]="{ item }">
                <v-chip-group column active-class="primary--text">
                  <v-chip
                    class="ma-1"
                    label
                    x-small
                    :color="colors[item.estatus]"
                    text-color="white"
                  >
                    {{ item.estatus }}
                  </v-chip>
                </v-chip-group>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon @click="printItem(item)">mdi-printer</v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        </v-tab-item>
      </v-tabs-items>
    </base-material-card>
  </v-col>
</template>

<script>
export default {
  props: {
    headers: Array,
    items: Array,
    title: String,
  },

  data: () => ({
    colors: {
      Pendiente: '#ffc107',
      Pagado: '#2ecc71',
      Finalizado: '#3498db',
      'En curso': '#9b59b6',
    },
    currentTab: 0,
    tabsHeaders: {
      recent: [],
      arriving: [],
      departing: [],
    },
    search: undefined,
    tabs: 0,
    travelScheduleTypes: [],
    travels: {
      recent: [],
      arriving: [],
      departing: [],
    },
  }),

  created() {
    this.initializeHeaders()
    this.initializeTravelsTabsData()
    this.getTravelsKeys()
  },

  methods: {
    printItem(item) {
      console.log('Printing:', item)
    },

    initializeHeaders() {
      this.tabsHeaders.recent = [
        ...this.headers,
        { sortable: false, text: 'Actions', value: 'actions' },
      ]

      this.tabsHeaders.arriving = [
        ...this.headers,
        { sortable: false, text: 'Destino', value: 'destino' },
        { sortable: true, text: 'Hora', value: 'hora' },
        { sortable: false, text: 'Actions', value: 'actions' },
      ]

      this.tabsHeaders.departing = [
        ...this.headers,
        { sortable: false, text: 'Origen', value: 'origen' },
        { sortable: true, text: 'Hora', value: 'hora' },
        { sortable: false, text: 'Actions', value: 'actions' },
      ]
    },

    initializeTravelsTabsData() {
      this.setRecentTravels()
      this.setArrivingTodayTravels()
      this.setDepartingTodayTravels()
    },

    setRecentTravels() {
      this.recentHeaders = this.headers
      this.travels.recent = this.items
    },

    setArrivingTodayTravels() {
      this.travels.arriving = this.items.filter((item) => item.origen === 'Aeropuerto')
    },

    setDepartingTodayTravels() {
      this.travels.departing = this.items.filter((item) => item.origen !== 'Aeropuerto')
    },

    getTravelsKeys() {
      return Object.keys(this.travels)
    },
  },
}
</script>
